import React, { forwardRef } from "react";
import { Grid, Header, Icon, List, ListItem } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import { skillsArray } from "../../constants/skills";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

const SkillsComponent = forwardRef(function (_, ref) {
  return (
    <div ref={ref}>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row className="mb-5">
              <Grid.Column width={8}>
                <Header className="home-header text-start">Skills</Header>
                <p style={{ fontSize: "1.33em", textAlign: "start" }}>
                  With over 5 years of experience in software development, I
                  have mastered the art of building scalable, efficient, and
                  maintainable systems across diverse environments. My expertise
                  spans full-stack development, cloud infrastructure, AI/ML
                  integration, and blockchain technologies. I specialize in
                  leveraging the right tools and frameworks—such as .NET,
                  Spring, React, Angular, Vue, AdonisJS, and Docker—to deliver
                  robust solutions tailored to business needs. Whether it's
                  architecting complex systems, optimizing performance, or
                  leading cross-functional teams, I bring a results-driven
                  approach to every project.
                </p>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <div align="center">
                        {skillsArray(50).map((skill, i) => (
                          <code key={i}>
                            <img
                              width={skill.width}
                              src={skill.src}
                              alt={skill.alt}
                              title={skill.title}
                            />
                          </code>
                        ))}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid columns="equal" stackable>
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"6"} className="pb-5 pt-5">
                <Header as="h3" className="fs-2">
                  <Icon name="lightbulb" />
                  Logic of Development
                </Header>
                <p className="fs-3">
                  <b>Sustainability</b> is the keyword in software development.
                  All principles, techniques, and rules emerge from this key
                  element. The quality of software is evaluated according to its
                  sustainability. I consider all these factors in my work.
                </p>
              </Grid.Column>
              <Grid.Column width={"6"} className="pb-5 pt-5">
                <Header as="h3" className="fs-2">
                  <Icon name="sitemap" />
                  Problem Solving
                </Header>
                <p className="fs-3">
                  With 5+ years of experience in software development, I have
                  solved numerous problems and learned various techniques to
                  debug and resolve errors efficiently. I have a deep
                  understanding of how to tackle complex issues.
                </p>
              </Grid.Column>
            </Grid.Row>
            {/* Divider */}
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"6"} className="pt-5 pb-5">
                <Header as="h3" className="fs-2">
                  <Icon name="settings" />
                  Project Management
                </Header>
                <p className="fs-3">
                  I have managed multiple projects, both individually and as
                  part of a team. I can anticipate potential challenges, manage
                  timelines, and ensure smooth project execution.
                </p>
              </Grid.Column>
              <Grid.Column width={"6"} className="pt-5 pb-5">
                <Header as="h3" className="fs-2">
                  <Icon name="users" />
                  Team Collaboration
                </Header>
                <p className="fs-3">
                  I thrive in team environments. I am open to feedback, willing
                  to admit mistakes, and work collaboratively to improve. I
                  enjoy team events, games, and fostering a positive work
                  culture.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"12"} className="pt-1 pb-5">
                <Header as="h3" className="fs-2">
                  <Icon name="list ul" />
                  Technical Information
                </Header>
                <List>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-small-2 mb-small-1">
                          Frontend
                        </List.Header>
                        <ListItem>React</ListItem>
                        <ListItem>Angular</ListItem>
                        <ListItem>NextJS</ListItem>
                        <ListItem>Vue (with Pinia, Unplugin...)</ListItem>
                        <ListItem>Bootstrap</ListItem>
                        <ListItem>Quasar</ListItem>
                        <ListItem>Vuetify</ListItem>
                        <ListItem>Semantic UI</ListItem>
                        <ListItem>Material UI</ListItem>
                        <ListItem>Vite</ListItem>
                      </Grid.Column>

                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-1 mb-small-1">
                          Backend
                        </List.Header>
                        <ListItem>.NET</ListItem>
                        <ListItem>Spring Framework</ListItem>
                        <ListItem>AdonisJS</ListItem>
                        <ListItem>ExpressJS</ListItem>
                        <ListItem>ASP.NET</ListItem>
                        <ListItem>EF Core</ListItem>
                        <ListItem>Hibernate & JPA</ListItem>
                        <ListItem>Lucid ORM</ListItem>
                        <ListItem>Vite</ListItem>
                      </Grid.Column>

                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-1 mb-small-1">
                          Databases
                        </List.Header>
                        <ListItem>PostgreSQL, MsSql, MySql, Sqlite</ListItem>
                        <ListItem>MongoDb</ListItem>
                        <ListItem>Redis</ListItem>
                        <ListItem>pg-vectors</ListItem>
                      </Grid.Column>

                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-1 mb-small-1">
                          AI/ML (Basic Level)
                        </List.Header>
                        <ListItem>TensorFlow</ListItem>
                        <ListItem>NumPy</ListItem>
                        <ListItem>Pandas</ListItem>
                        <ListItem>Scikit-learn</ListItem>
                        <ListItem>Data Preparation</ListItem>
                        <ListItem>Regressions</ListItem>
                        <ListItem>Perceptron and Neuron Cell Concepts</ListItem>
                        <ListItem>
                          Deep Learning and Machine Learning Concepts
                        </ListItem>
                        <ListItem>
                          Data Clustering and Classification Concepts
                        </ListItem>
                      </Grid.Column>

                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header
                          className="fs-2 fw-b mt-1 mb-small-1"
                          style={{ lineHeight: "30px" }}
                        >
                          Terms, Tools & Technologies
                        </List.Header>
                        <ListItem>Decentralized Systems (DeFi)</ListItem>
                        <ListItem>Bitcoin Blockchains</ListItem>
                        <ListItem>Recommendation Algorithms (Gorse)</ListItem>
                        <ListItem>Search Engine Optimization</ListItem>
                      </Grid.Column>

                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header
                          className="fs-2 fw-b mt-1 mb-small-1"
                          style={{ lineHeight: "30px" }}
                        >
                          Design Patterns & Principles
                        </List.Header>
                        <ListItem>Inversion of Control (IoC)</ListItem>
                        <ListItem>Dependency Injection (DI)</ListItem>
                        <ListItem>SOLID</ListItem>
                        <ListItem>Mediator Pattern</ListItem>
                        <ListItem>Strategy Pattern</ListItem>
                        <ListItem>Repository Pattern</ListItem>
                        <ListItem>... too many to write here</ListItem>
                      </Grid.Column>
                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-1 mb-small-1">
                          Architectural Patterns
                        </List.Header>
                        <ListItem>CQRS</ListItem>
                        <ListItem>Event-Driven</ListItem>
                        <ListItem>Domain-Driven</ListItem>
                        <ListItem>Onion Architecture</ListItem>
                        <ListItem>Hexagonal Architecture</ListItem>
                        <ListItem>Microservices Architecture</ListItem>
                        <ListItem>
                          Layered Architectures (Can evaluate with pros and
                          cons)
                        </ListItem>
                      </Grid.Column>
                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-1 mb-small-1">
                          Clouds & Virtualization
                        </List.Header>
                        <ListItem>Docker</ListItem>
                        <ListItem>Docker Compose</ListItem>
                        <ListItem>Kubernetes</ListItem>
                        <ListItem>Reverse Proxy (like Nginx)</ListItem>
                        <ListItem>IIS</ListItem>
                        <ListItem>Apache</ListItem>
                        <ListItem>VMs (Droplet)</ListItem>
                        <ListItem>SSH, SCP & some other protocols</ListItem>
                        <ListItem>Firewall Configurations</ListItem>
                      </Grid.Column>

                      <Grid.Column mobile={"8"} tablet={"8"} computer={"4"}>
                        <List.Header className="fs-2 fw-b mt-1 mb-small-1">
                          Soft Skills
                        </List.Header>
                        <ListItem>Rapid Learning</ListItem>
                        <ListItem>Problem Solving</ListItem>
                        <ListItem>Teamwork</ListItem>
                        <ListItem>Leadership</ListItem>
                        <ListItem>Adaptability</ListItem>
                        <ListItem>Clean Code Practices</ListItem>
                        <ListItem>Documentation Reading & Writing</ListItem>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
        <Media at="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row className="mb-5">
              <Grid.Column width={16} floated="left" className="mb-5">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <div align="center">
                        {skillsArray(75).map((skill, i) => (
                          <code key={i}>
                            <img
                              width={skill.width}
                              src={skill.src}
                              alt={skill.alt}
                              title={skill.title}
                            />
                          </code>
                        ))}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={16}>
                <Header className="home-header text-start">Skills</Header>
                <p style={{ fontSize: "3.6em", textAlign: "start" }}>
                  With over 5 years of experience in software development, I
                  have mastered the art of building scalable, efficient, and
                  maintainable systems across diverse environments. My expertise
                  spans full-stack development, cloud infrastructure, AI/ML
                  integration, and blockchain technologies. I specialize in
                  leveraging the right tools and frameworks—such as .NET,
                  Spring, React, Angular, Vue, AdonisJS, and Docker—to deliver
                  robust solutions tailored to business needs. Whether it's
                  architecting complex systems, optimizing performance, or
                  leading cross-functional teams, I bring a results-driven
                  approach to every project.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Grid columns="equal" stackable>
            <Grid.Row
              textAlign="center"
              centered
              className="ps-2 pe-2 ms-1 me-1"
            >
              <Grid.Column width={"16"} className="pb-5 pt-5 ">
                <Header as="h1" style={{ fontSize: "3.6em" }}>
                  <Icon name="lightbulb" size="massive" />
                  Logic of Development
                </Header>
                <p style={{ fontSize: "2.6em", textAlign: "start" }}>
                  <b>Sustainability</b> is the keyword in software development.
                  All principles, techniques, and rules emerge from this key
                  element. The quality of software is evaluated according to its
                  sustainability. I consider all these factors in my work.
                </p>
              </Grid.Column>
              <Grid.Column width={"16"} className="pb-5 pt-5">
                <Header as="h3" style={{ fontSize: "3.6em" }}>
                  <Icon name="sitemap" />
                  Problem Solving
                </Header>
                <p style={{ fontSize: "2.6em", textAlign: "start" }}>
                  With 5+ years of experience in software development, I have
                  solved numerous problems and learned various techniques to
                  debug and resolve errors efficiently. I have a deep
                  understanding of how to tackle complex issues.
                </p>
              </Grid.Column>
            </Grid.Row>
            {/* Divider */}
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"16"} className="pt-5 pb-5">
                <Header as="h3" style={{ fontSize: "3.6em" }}>
                  <Icon name="settings" />
                  Project Management
                </Header>
                <p style={{ fontSize: "2.6em", textAlign: "start" }}>
                  I have managed multiple projects, both individually and as
                  part of a team. I can anticipate potential challenges, manage
                  timelines, and ensure smooth project execution.
                </p>
              </Grid.Column>
              <Grid.Column width={"16"} className="pt-5 pb-5">
                <Header as="h3" style={{ fontSize: "3.6em" }}>
                  <Icon name="users" />
                  Team Collaboration
                </Header>
                <p style={{ fontSize: "2.6em", textAlign: "start" }}>
                  I thrive in team environments. I am open to feedback, willing
                  to admit mistakes, and work collaboratively to improve. I
                  enjoy team events, games, and fostering a positive work
                  culture.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="center" centered className="ps-2 pe-2">
              <Grid.Column width={"16"} className="pt-1 pb-5">
                <Header as="h3" style={{ fontSize: "3.6em" }}>
                  <Icon name="list ul" />
                  Technical Information
                </Header>
                <List>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Frontend
                            </List.Header>
                            <ListItem>React</ListItem>
                            <ListItem>Angular</ListItem>
                            <ListItem>NextJS</ListItem>
                            <ListItem>Vue (with Pinia, Unplugin...)</ListItem>
                            <ListItem>Bootstrap</ListItem>
                            <ListItem>Quasar</ListItem>
                            <ListItem>Material UI</ListItem>
                            <ListItem>Vuetify</ListItem>
                            <ListItem>Semantic UI</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>

                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Backend
                            </List.Header>
                            <ListItem>.NET</ListItem>
                            <ListItem>Spring Framework</ListItem>
                            <ListItem>AdonisJS</ListItem>
                            <ListItem>ExpressJS</ListItem>
                            <ListItem>ASP.NET</ListItem>
                            <ListItem>EF Core</ListItem>
                            <ListItem>Hibernate & JPA</ListItem>
                            <ListItem>Lucid ORM</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>

                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Databases
                            </List.Header>
                            <ListItem>
                              PostgreSQL, MsSql, MySql, Sqlite
                            </ListItem>
                            <ListItem>MongoDb</ListItem>
                            <ListItem>Redis</ListItem>
                            <ListItem>pg-vectors</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>

                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              AI/ML (Basic Level)
                            </List.Header>
                            <ListItem>TensorFlow</ListItem>
                            <ListItem>NumPy</ListItem>
                            <ListItem>Pandas</ListItem>
                            <ListItem>Scikit-learn</ListItem>
                            <ListItem>Data Preparation</ListItem>
                            <ListItem>Regressions</ListItem>
                            <ListItem>
                              Perceptron and Neuron Cell Concepts
                            </ListItem>
                            <ListItem>
                              Deep Learning and Machine Learning Concepts
                            </ListItem>
                            <ListItem>
                              Data Clustering and Classification Concepts
                            </ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>

                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Terms, Tools & Technologies
                            </List.Header>
                            <ListItem>Decentralized Systems (DeFi)</ListItem>
                            <ListItem>Bitcoin Blockchains</ListItem>
                            <ListItem>
                              Recommendation Algorithms (Gorse)
                            </ListItem>
                            <ListItem>Search Engine Optimization</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>

                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Design Patterns & Principles
                            </List.Header>
                            <ListItem>Inversion of Control (IoC)</ListItem>
                            <ListItem>Dependency Injection (DI)</ListItem>
                            <ListItem>SOLID</ListItem>
                            <ListItem>Mediator Pattern</ListItem>
                            <ListItem>Strategy Pattern</ListItem>
                            <ListItem>Repository Pattern</ListItem>
                            <ListItem>... too many to write here</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>
                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Architectural Patterns
                            </List.Header>
                            <ListItem>CQRS</ListItem>
                            <ListItem>Event-Driven</ListItem>
                            <ListItem>Domain-Driven</ListItem>
                            <ListItem>Onion Architecture</ListItem>
                            <ListItem>Hexagonal Architecture</ListItem>
                            <ListItem>Microservices Architecture</ListItem>
                            <ListItem>
                              Layered Architectures (Can evaluate with pros and
                              cons)
                            </ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>
                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Clouds & Virtualization
                            </List.Header>
                            <ListItem>Docker</ListItem>
                            <ListItem>Docker Compose</ListItem>
                            <ListItem>Kubernetes</ListItem>
                            <ListItem>Reverse Proxy (like Nginx)</ListItem>
                            <ListItem>IIS</ListItem>
                            <ListItem>Apache</ListItem>
                            <ListItem>VMs (Droplet)</ListItem>
                            <ListItem>SSH, SCP & some other protocols</ListItem>
                            <ListItem>Firewall Configurations</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>

                      <Grid.Column
                        mobile={"8"}
                        tablet={"8"}
                        computer={"4"}
                        className="mb-1"
                      >
                        <List size={"massive"}>
                          <List.Content className="sm-list">
                            <List.Header className="sm-list-title fw-b mt-small-2 mb-small-1">
                              Soft Skills
                            </List.Header>
                            <ListItem>Rapid Learning</ListItem>
                            <ListItem>Problem Solving</ListItem>
                            <ListItem>Teamwork</ListItem>
                            <ListItem>Leadership</ListItem>
                            <ListItem>Adaptability</ListItem>
                            <ListItem>Clean Code Practices</ListItem>
                            <ListItem>Documentation Reading & Writing</ListItem>
                          </List.Content>
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
});

export default SkillsComponent;

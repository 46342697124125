import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Header, Icon } from "semantic-ui-react";

import { createMedia } from "@artsy/fresnel";
import "./HomeComponent.css";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

export default function HomeComponent() {
  return (
    <div>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Container inverted text>
            <Header as="h1" content="Fatih Dağ" inverted className="header1" />
            <Header
              as="h6"
              content="Mid-Level Software Developer"
              inverted
              className="fs-2 fw-n text-grey"
            />
            <Header
              as="h2"
              content="Adapting to new technologies, I build robust systems grounded in strong fundamentals."
              inverted
              className="subheader1"
            />
            <Button
              inverted
              size="massive"
              as={Link}
              to="/home/about"
              color="green"
              className="mt-1"
            >
              About
              <Icon name="right arrow" />
            </Button>
          </Container>
        </Media>
        <Media at="mobile">
          <Container inverted text fluid>
            <Header as="h1" content="Fatih Dağ" inverted className="header1" />
            <Header
              as="h3"
              content="Mid-Level Software Developer"
              inverted
              className="fw-n text-grey mb-2"
              style={{ fontSize: "2.7em" }}
            />
            <Header
              as="h2"
              content="Adapting to new technologies, I build robust systems grounded in strong fundamentals."
              inverted
              className="subheader1"
            />
            <Button
              inverted
              as={Link}
              to="/home/about"
              color="green"
              size="massive"
              className="mt-1 mb-3"
              style={{ fontSize: "60px" }}
            >
              About
              <Icon size="large" name="right arrow" />
            </Button>
          </Container>
        </Media>
      </MediaContextProvider>
    </div>
  );
}

import React, { forwardRef } from "react";
import { Grid, Header, Icon, Image, List } from "semantic-ui-react";
import ResumeViewer from "../resume/ResumeViewer";
import { links } from "../../constants/Links";
import { images } from "../../constants/Images";
import "./AboutComponent.css";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

const AboutComponent = forwardRef(function (_, ref) {
  return (
    <div ref={ref}>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row className="mb-0">
              <Grid.Column width={8}>
                <Header className="home-header">About.</Header>
                <p style={{ fontSize: "1.33em", textAlign: "start" }}>
                  I discovered my passion for programming at the age of 13,
                  starting with C and later diving into .NET development during
                  high school. Over the years, I’ve expanded my expertise to
                  include Java, JavaScript, Python, and a wide range of modern
                  frameworks and technologies. My journey has been driven by a
                  relentless curiosity and a commitment to mastering the
                  fundamentals of software development. Today, I specialize in
                  building robust, scalable systems and thrive on solving
                  complex problems with innovative solutions.
                </p>
                <List floated="left" horizontal>
                  <List.Item target="_blank" href={links["github"]}>
                    <Icon name="github" size="big" />
                  </List.Item>
                  <List.Item target="_blank" href={links["instagram"]}>
                    <Icon name="instagram" size="big" />
                  </List.Item>
                  <List.Item target="_blank" href={links["linkedin"]}>
                    <Icon name="linkedin" size="big" />
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Image rounded size="large" src={images["home1"]} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="ms-0">
              <Grid.Column floated="left" width={16}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "auto" }}>
                    <ResumeViewer
                      color="grey"
                      floated="left"
                      btnText="View Resume"
                    ></ResumeViewer>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
        <Media at="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row className="mb-2">
              <Grid.Column>
                <Image
                  rounded
                  size="huge"
                  src={images["home1"]}
                  floated="left"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="mb-0">
              <Grid.Column>
                <Header className="home-header">About.</Header>
                <p style={{ fontSize: "3.6em", textAlign: "start" }}>
                  I discovered my passion for programming at the age of 13,
                  starting with C and later diving into .NET development during
                  high school. Over the years, I’ve expanded my expertise to
                  include Java, JavaScript, Python, and a wide range of modern
                  frameworks and technologies. My journey has been driven by a
                  relentless curiosity and a commitment to mastering the
                  fundamentals of software development. Today, I specialize in
                  building robust, scalable systems and thrive on solving
                  complex problems with innovative solutions.
                </p>
                <List floated="left" horizontal size="massive">
                  <List.Item
                    target="_blank"
                    href={links["github"]}
                    className="me-1"
                  >
                    <Icon name="github" size="huge" />
                  </List.Item>
                  <List.Item
                    target="_blank"
                    href={links["instagram"]}
                    className="me-1"
                  >
                    <Icon name="instagram" size="huge" />
                  </List.Item>
                  <List.Item target="_blank" href={links["linkedin"]}>
                    <Icon name="linkedin" size="huge" />
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="ms-0">
              <Grid.Column>
                <ResumeViewer
                  color="grey"
                  floated="left"
                  btnText="View Resume"
                ></ResumeViewer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
});

export default AboutComponent;

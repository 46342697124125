import React, { forwardRef } from "react";
import {
  CardMeta,
  CardHeader,
  CardDescription,
  CardContent,
  Card,
  Grid,
  Header,
  List,
  ListItem,
  ListHeader,
} from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

const ProjectsComponent = forwardRef(function (_, ref) {
  return (
    <div ref={ref}>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={"12"}>
                <Header className="home-header text-start">Projects</Header>
                <p
                  className="mb-2"
                  style={{ fontSize: "1.33em", textAlign: "start" }}
                >
                  You can see my all big previous projects below.
                </p>
              </Grid.Column>
              <Grid.Column width={"16"}>
                <Grid>
                  <Grid.Row>
                    <Header className="text-start fs-2 mb-1">
                      Professional Work
                    </Header>
                    <Grid.Column width={"16"} className="mb-1">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            Ekon Software and Tech Company
                          </CardHeader>
                          <CardMeta>2023 Dec – 2024 Sept</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer (Intern → Part-Time)
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Migrated a ReactJS project to NextJS,
                                    improving performance and scalability.
                                  </ListItem>
                                  <ListItem>
                                    Restructured the backend architecture using
                                    Mediator and Strategy patterns, adhering to
                                    SOLID principles.
                                  </ListItem>
                                  <ListItem>
                                    Developed the admin panel backend and
                                    enhanced its ReactJS frontend with features
                                    like guards and routing management.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Microservices, React, NextJS, .NET, Mediator
                                Pattern, Strategy Pattern, SOLID principles,
                                Bitcoin Apps, Payment Systems.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Header className="text-start fs-2 mb-1">
                      Freelance Works
                    </Header>
                    <Grid.Column width={"16"} className="mb-2">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>Health Tourism Project</CardHeader>
                          <CardMeta>2024 June – Sept</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Independently learned and applied AdonisJS,
                                    Vue, Docker, MiniO, PostgreSQL, and Redis to
                                    deliver a fully functional platform.
                                  </ListItem>
                                  <ListItem>
                                    Developed a health tourism platform
                                    featuring clinic filtering, doctor profiles,
                                    and AI-powered city exploration using
                                    ChatGPT API.
                                  </ListItem>
                                  <ListItem>
                                    Managed and integrated 3,000+ real data
                                    entries using Faker and seeders, ensuring
                                    seamless data migration and scalability.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                TypeScript, AdonisJS, Vue, Docker, PostgreSQL,
                                Redis, AI integration (ChatGPT API), CDN tools
                                (MiniO), recommendation algorithms (Gorse).
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-2">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            Dynamic Landing Website for Construction Company
                          </CardHeader>
                          <CardMeta>2024 July – Sept</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Team Leader, Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Led a team of three to develop a
                                    data-intensive admin panel and landing page
                                    using Vue, .NET, and Vuetify.
                                  </ListItem>
                                  <ListItem>
                                    Delivered the frontend in two weeks,
                                    demonstrating strong leadership and rapid
                                    development skills.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Vue, .NET, Vuetify, team leadership, rapid
                                development.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-2">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>4 Websites with One Backend</CardHeader>
                          <CardMeta>2024 Aug – Oct</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Developed three distinct landing pages
                                    (dental clinics, construction company, café)
                                    powered by a single backend and admin panel.
                                  </ListItem>
                                  <ListItem>
                                    Used Vue, Quasar CLI, and AdonisJS to create
                                    responsive, mobile-first designs.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Vue, Quasar, AdonisJS, API integration,
                                mobile-first design, Docker and compose, VM
                                deployment, redis.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Header className="text-start fs-2 mb-1">
                      Academic/Competition Projects
                    </Header>
                    <Grid.Column width={"16"} className="mb-1">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            TUBITAK Project – Historical Artifacts’ Epitaphs
                          </CardHeader>
                          <CardMeta>2023 Jan – 2024 Feb</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Built a website and admin panel for
                                    translating historical epitaphs using
                                    Angular, ASP.NET, and MSSQL.
                                  </ListItem>
                                  <ListItem>
                                    Managed data entry and localization for
                                    150+ artifacts, ensuring accurate and
                                    accessible translations.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Angular, ASP.NET, EF Core, MSSQL, layered
                                architecture, localization.
                              </ListItem>
                              <ListItem>
                                <ListHeader>Visit The Project</ListHeader>
                                <a
                                  href="https://konyakitabeleri.com.tr"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  https://konyakitabeleri.com.tr
                                </a>
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-1">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            Transportathon Hackathon Competition
                          </CardHeader>
                          <CardMeta>2023 Sep</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Developed a transportation simulation
                                    project using CQRS, Onion Architecture,
                                    .NET, and Angular in 9 days.
                                  </ListItem>
                                  <ListItem>
                                    Implemented real-time communication with
                                    SignalR and created a user-friendly
                                    frontend.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                CQRS, Onion Architecture, .NET, Angular,
                                SignalR, rapid prototyping.
                              </ListItem>
                              <ListItem>
                                <ListHeader>Visit Source Codes</ListHeader>
                                <a
                                  href="https://github.com/Dagbfatih/TransportathonHackathonProject"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  https://github.com/Dagbfatih/TransportathonHackathonProject
                                </a>
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-1">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            Introduction Website for a Custom Company
                          </CardHeader>
                          <CardMeta>2022 Apr – Jun</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Frontend Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Built a custom introduction website using
                                    Angular 12, focusing on SEO and clean code.
                                  </ListItem>
                                  <ListItem>
                                    Designed the site to be fully responsive and
                                    optimized for future updates.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Angular, Bootstrap, SEO, clean code.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-1">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            Online Education Technologies (Personal Project)
                          </CardHeader>
                          <CardMeta>2022 Apr – Sep</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Developed an online education platform with
                                    features like test creation, rating systems,
                                    and online exams.
                                  </ListItem>
                                  <ListItem>
                                    Designed the platform to be scalable and
                                    user-friendly, with plans for future
                                    enhancements like PDF conversion.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Angular, ASP.NET, EF Core, complex architecture,
                                exception management.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-1">
                      <Card fluid>
                        <CardContent textAlign="left">
                          <CardHeader>
                            Car Rental Project (.NET Bootcamp)
                          </CardHeader>
                          <CardMeta>2021 Feb – Jun</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Built a car rental platform using ASP.NET,
                                    EF Core, and Angular as part of a bootcamp.
                                  </ListItem>
                                  <ListItem>
                                    Implemented RESTful APIs, IoC, DI, and
                                    caching for improved performance.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                ASP.NET, EF Core, Angular, RESTful APIs, IoC,
                                DI.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
        <Media at="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
            <Grid.Column width={"16"}>
                <Header className="home-header text-start">Projects</Header>
                <p
                  className="mb-2"
                  style={{ fontSize: "3.6em", textAlign: "start" }}
                >
                  You can see my all big previous projects below.
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid stackable >
            <Grid.Row centered className="ps-2 pe-2 ms-1 me-1">
              <Grid.Column width={"16"}>
                <Grid>
                  <Grid.Row>
                    <Header
                      className="text-start mb-1"
                      style={{ fontSize: "3.2em" }}
                    >
                      Professional Works
                    </Header>
                    <Grid.Column width={"16"} className="mb-5">
                      <Card color='grey' fluid className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            Ekon Software and Tech Company
                          </CardHeader>
                          <CardMeta>2023 Dec – 2024 Sept</CardMeta>
                          <CardDescription>
                            <List size="large">
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer (Intern → Part-Time)
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Migrated a ReactJS project to NextJS,
                                    improving performance and scalability.
                                  </ListItem>
                                  <ListItem>
                                    Restructured the backend architecture using
                                    Mediator and Strategy patterns, adhering to
                                    SOLID principles.
                                  </ListItem>
                                  <ListItem>
                                    Developed the admin panel backend and
                                    enhanced its ReactJS frontend with features
                                    like guards and routing management.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Microservices, React, NextJS, .NET, Mediator
                                Pattern, Strategy Pattern, SOLID principles,
                                Bitcoin Apps, Payment Systems.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Header className="text-start mb-1" style={{ fontSize: "3.2em" }}>
                      Freelance Works
                    </Header>
                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>Health Tourism Project</CardHeader>
                          <CardMeta>2024 June – Sept</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Independently learned and applied AdonisJS,
                                    Vue, Docker, MiniO, PostgreSQL, and Redis to
                                    deliver a fully functional platform.
                                  </ListItem>
                                  <ListItem>
                                    Developed a health tourism platform
                                    featuring clinic filtering, doctor profiles,
                                    and AI-powered city exploration using
                                    ChatGPT API.
                                  </ListItem>
                                  <ListItem>
                                    Managed and integrated 3,000+ real data
                                    entries using Faker and seeders, ensuring
                                    seamless data migration and scalability.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                TypeScript, AdonisJS, Vue, Docker, PostgreSQL,
                                Redis, AI integration (ChatGPT API), CDN tools
                                (MiniO), recommendation algorithms (Gorse).
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            Dynamic Landing Website for Construction Company
                          </CardHeader>
                          <CardMeta>2024 July – Sept</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Team Leader, Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Led a team of three to develop a
                                    data-intensive admin panel and landing page
                                    using Vue, .NET, and Vuetify.
                                  </ListItem>
                                  <ListItem>
                                    Delivered the frontend in two weeks,
                                    demonstrating strong leadership and rapid
                                    development skills.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Vue, .NET, Vuetify, team leadership, rapid
                                development.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>4 Websites with One Backend</CardHeader>
                          <CardMeta>2024 Aug – Oct</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Developed three distinct landing pages
                                    (dental clinics, construction company, café)
                                    powered by a single backend and admin panel.
                                  </ListItem>
                                  <ListItem>
                                    Used Vue, Quasar CLI, and AdonisJS to create
                                    responsive, mobile-first designs.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Vue, Quasar, AdonisJS, API integration,
                                mobile-first design, Docker and compose, VM
                                deployment, redis.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Header className="text-start mb-1" style={{ fontSize: "3.2em" }}>
                      Academic/Competition Projects
                    </Header>
                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            TUBITAK Project – Historical Artifacts’ Epitaphs
                          </CardHeader>
                          <CardMeta>2023 Jan – 2024 Feb</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Built a website and admin panel for
                                    translating historical epitaphs using
                                    Angular, ASP.NET, and MSSQL.
                                  </ListItem>
                                  <ListItem>
                                    Managed data entry and localization for
                                    150+ artifacts, ensuring accurate and
                                    accessible translations.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Angular, ASP.NET, EF Core, MSSQL, layered
                                architecture, localization.
                              </ListItem>
                              <ListItem>
                                <ListHeader>Visit The Project</ListHeader>
                                <a
                                  href="https://konyakitabeleri.com.tr"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  https://konyakitabeleri.com.tr
                                </a>
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            Transportathon Hackathon Competition
                          </CardHeader>
                          <CardMeta>2023 Sep</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Developed a transportation simulation
                                    project using CQRS, Onion Architecture,
                                    .NET, and Angular in 9 days.
                                  </ListItem>
                                  <ListItem>
                                    Implemented real-time communication with
                                    SignalR and created a user-friendly
                                    frontend.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                CQRS, Onion Architecture, .NET, Angular,
                                SignalR, rapid prototyping.
                              </ListItem>
                              <ListItem>
                                <ListHeader>Visit Source Codes</ListHeader>
                                <a
                                  href="https://github.com/Dagbfatih/TransportathonHackathonProject"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  https://github.com/Dagbfatih/TransportathonHackathonProject
                                </a>
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            Introduction Website for a Custom Company
                          </CardHeader>
                          <CardMeta>2022 Apr – Jun</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Frontend Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Built a custom introduction website using
                                    Angular 12, focusing on SEO and clean code.
                                  </ListItem>
                                  <ListItem>
                                    Designed the site to be fully responsive and
                                    optimized for future updates.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Angular, Bootstrap, SEO, clean code.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            Online Education Technologies (Personal Project)
                          </CardHeader>
                          <CardMeta>2022 Apr – Sep</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Developed an online education platform with
                                    features like test creation, rating systems,
                                    and online exams.
                                  </ListItem>
                                  <ListItem>
                                    Designed the platform to be scalable and
                                    user-friendly, with plans for future
                                    enhancements like PDF conversion.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                Angular, ASP.NET, EF Core, complex architecture,
                                exception management.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>

                    <Grid.Column width={"16"} className="mb-5">
                      <Card fluid color='grey' className="fs-1">
                        <CardContent textAlign="left">
                          <CardHeader>
                            Car Rental Project (.NET Bootcamp)
                          </CardHeader>
                          <CardMeta>2021 Feb – Jun</CardMeta>
                          <CardDescription>
                            <List>
                              <ListItem>
                                <ListHeader>Role</ListHeader>
                                Full-Stack Developer
                              </ListItem>
                              <ListItem>
                                <ListHeader>Key Achievements</ListHeader>
                                <List bulleted>
                                  <ListItem>
                                    Built a car rental platform using ASP.NET,
                                    EF Core, and Angular as part of a bootcamp.
                                  </ListItem>
                                  <ListItem>
                                    Implemented RESTful APIs, IoC, DI, and
                                    caching for improved performance.
                                  </ListItem>
                                </List>
                              </ListItem>
                              <ListItem>
                                <ListHeader>Skills Demonstrated</ListHeader>
                                ASP.NET, EF Core, Angular, RESTful APIs, IoC,
                                DI.
                              </ListItem>
                            </List>
                          </CardDescription>
                        </CardContent>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
});

export default ProjectsComponent;

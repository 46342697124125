import React, { useState } from "react";
import "./Navi.css";
import { Link } from "react-router-dom";
import {
  Container,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

export default function Navi({ children }) {
  let header = useParams()["header"];
  useEffect(() => {}, [header]);

  const [sidebarOpened, setSidebarOpened] = useState(true);

  useEffect(() => {
    setSidebarOpened(!sidebarOpened);
  }, []);

  return (
    <div>
      <MediaContextProvider>
        {/* Computer */}
        <Media greaterThan="mobile">
          <Segment inverted className="border-radius-none mb-0">
            <Container>
              <Menu compact widths={5} inverted pointing secondary size="massive">
                <Menu.Item
                  name="Home"
                  active={header === undefined}
                  as={Link}
                  to="/home"
                />
                <Menu.Item
                  name="About"
                  active={header === "about"}
                  as={Link}
                  to="/home/about"
                />
                <Menu.Item
                  name="Experience"
                  active={header === "experience"}
                  as={Link}
                  to="/home/experience"
                />
                <Menu.Item
                  name="Skills"
                  active={header === "skills"}
                  as={Link}
                  to="/home/skills"
                />
                <Menu.Item
                  name="Projects"
                  active={header === "projects"}
                  as={Link}
                  to="/home/projects"
                />
                <Menu.Item
                  name="Contact"
                  active={header === "contact"}
                  as={Link}
                  to="/home/contact"
                />
              </Menu>
            </Container>
          </Segment>
          {children}
        </Media>

        {/* Mobile */}
        <Media at="mobile">
          <Sidebar.Pushable>
            <Sidebar
              as={Menu}
              animation="overlay"
              direction="left"
              inverted
              onHide={() => setSidebarOpened(false)}
              vertical
              visible={sidebarOpened}
              width="very wide"
              className="fs-1"
            >
              <Menu.Item className="mb-2 pt-1 borderless">
                <Icon
                  name="close"
                  size="big"
                  onClick={() => setSidebarOpened(false)}
                />
              </Menu.Item>

              <Menu.Item
                name="Home"
                active={header === undefined}
                as={Link}
                to="/home"
                onClick={() => setSidebarOpened(false)}
              />
              <Menu.Item
                name="About"
                active={header === "about"}
                as={Link}
                to="/home/about"
                onClick={() => setSidebarOpened(false)}
              />
              <Menu.Item
                name="Experience"
                active={header === "experience"}
                as={Link}
                to="/home/experience"
                onClick={() => setSidebarOpened(false)}
              />
              <Menu.Item
                name="Skills"
                active={header === "skills"}
                as={Link}
                to="/home/skills"
                onClick={() => setSidebarOpened(false)}
              />
              <Menu.Item
                name="Projects"
                active={header === "projects"}
                as={Link}
                to="/home/projects"
                onClick={() => setSidebarOpened(false)}
              />
              <Menu.Item
                name="Contact"
                active={header === "contact"}
                as={Link}
                to="/home/contact"
                onClick={() => setSidebarOpened(false)}
              />
            </Sidebar>

            <Sidebar.Pusher dimmed={sidebarOpened}>
              <Segment inverted textAlign="left" vertical size="massive">
                <Container fluid className="ps-2">
                  <Menu inverted pointing secondary size="huge">
                    <Menu.Item onClick={() => setSidebarOpened(true)}>
                      <Icon size="huge" name="sidebar" />
                    </Menu.Item>
                  </Menu>
                </Container>
              </Segment>
              {children}
            </Sidebar.Pusher>
          </Sidebar.Pushable>
        </Media>
      </MediaContextProvider>
    </div>
  );
}

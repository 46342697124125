import { Grid, Header, List } from "semantic-ui-react";
import { links } from "../../constants/Links";
import React, { forwardRef } from "react";
import { createMedia } from "@artsy/fresnel";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    computer: 1024,
  },
});

const ContactComponent = forwardRef(function (_, ref) {
  return (
    <div ref={ref}>
      <MediaContextProvider>
        <Media greaterThan="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column width={8}>
                <Header className="home-header">Contact me</Header>
                <p style={{ fontSize: "1.33em", textAlign: "start" }}>
                  You can contact with me easily, here is my contact
                  information.
                </p>
              </Grid.Column>
              <Grid.Column width={6}></Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left">
                <List size="huge">
                  <List.Item>
                    <List.Content>
                      <List.Icon name="github" className="me-small-2" />
                      <a target="_blank" rel="noreferrer" href={links.github}>
                        Github
                      </a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="mail" className="me-small-2" />
                      <a href={"mailto:" + links.mail}>dagbfatih@hotmail.com</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Icon
                        name="linkedin"
                        className="me-small-2"
                      />
                      <a target="_blank" rel="noreferrer" href={links.linkedin}>
                        LinkedIn
                      </a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <List.Icon name="marker" className="me-small-2" />
                      Ankara, Turkey
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
        <Media at="mobile">
          <Grid container stackable verticalAlign="middle">
            <Grid.Row>
              <Grid.Column className="mb-3">
                <Header className="home-header">Contact</Header>
                <p style={{ fontSize: "3.6em", textAlign: "start" }}>
                  You can contact with me easily, here is my contact
                  information.
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="left">
                <List size="massive">
                  <List.Item>
                    <List.Content className="fs-1 mb-1">
                      <List.Icon
                        size="big"
                        name="github"
                        className="me-small-2"
                      />
                      <a target="_blank" rel="noreferrer" href={links.github}>
                        Github
                      </a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content className="fs-1 mb-1">
                      <List.Icon
                        size="big"
                        name="mail"
                        className="me-small-2"
                      />
                      <a href={"mailto:" + links.mail}>dagbfatih@hotmail.com</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content className="fs-1 mb-1">
                      <List.Icon
                        size="big"
                        name="phone"
                        rotated="clockwise"
                        className="me-small-2"
                      />
                      <a href="tel:+905511448492">(+90) 551 144 8492</a>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content className="fs-1">
                      <List.Icon
                        size="big"
                        name="marker"
                        className="me-small-2 "
                      />
                      Ankara, Turkey
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Media>
      </MediaContextProvider>
    </div>
  );
});

export default ContactComponent;

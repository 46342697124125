/**
 * data is fetched from https://marwin1991.github.io/profile-technology-icons/
 */
export const skillsArray = (width) => {
  return [
    {
      alt: "Spring Boot",
      title: "Spring Boot",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/spring_boot.png",
      width: width,
    },
    {
      alt: "Spring",
      title: "Spring",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/spring.png",
      width: width,
    },
    {
      alt: ".NET Core",
      title: ".NET Core",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/_net_core.png",
      width: width,
    },
    {
      alt: "Express",
      title: "Express",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/express.png",
      width: width,
    },
    {
      alt: "HTTP",
      title: "HTTP",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/http.png",
      width: width,
    },
    {
      alt: "C#",
      title: "C#",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/c%23.png",
      width: width,
    },
    {
      alt: "Java",
      title: "Java",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/java.png",
      width: width,
    },
    {
      alt: "TypeScript",
      title: "TypeScript",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/typescript.png",
      width: width,
    },
    {
      alt: "JavaScript",
      title: "JavaScript",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/javascript.png",
      width: width,
    },
    {
      alt: "C++",
      title: "C++",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/c++.png",
      width: width,
    },
    {
      alt: "C",
      title: "C",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/c.png",
      width: width,
    },
    {
      alt: "Python",
      title: "Python",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/python.png",
      width: width,
    },
    {
      alt: "Flutter",
      title: "Flutter",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/flutter.png",
      width: width,
    },
    {
      alt: "websocket",
      title: "websocket",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/websocket.png",
      width: width,
    },
    {
      alt: "Git",
      title: "Git",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/git.png",
      width: width,
    },
    {
      alt: "GitHub",
      title: "GitHub",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/github.png",
      width: width,
    },
    {
      alt: "WebAssembly",
      title: "WebAssembly",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/webassembly.png",
      width: width,
    },
    {
      alt: "HTML",
      title: "HTML",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/html.png",
      width: width,
    },
    {
      alt: "CSS",
      title: "CSS",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/css.png",
      width: width,
    },
    {
      alt: "Node.js",
      title: "Node.js",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/node_js.png",
      width: width,
    },
    {
      alt: "npm",
      title: "npm",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/npm.png",
      width: width,
    },
    {
      alt: "Angular",
      title: "Angular",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/angular.png",
      width: width,
    },
    {
      alt: "React",
      title: "React",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/react.png",
      width: width,
    },
    {
      alt: "Vue.js",
      title: "Vue.js",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/vue_js.png",
      width: width,
    },
    {
      alt: "Next.js",
      title: "Next.js",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/next_js.png",
      width: width,
    },
    {
      alt: "Vuetify.js",
      title: "Vuetify.js",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/vuetify_js.png",
      width: width,
    },
    {
      alt: "Bootstrap",
      title: "Bootstrap",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/bootstrap.png",
      width: width,
    },
    {
      alt: "Material UI",
      title: "Material UI",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/material_ui.png",
      width: width,
    },
    {
      alt: "Tailwind CSS",
      title: "Tailwind CSS",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/tailwind_css.png",
      width: width,
    },
    {
      alt: "Redux",
      title: "Redux",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/redux.png",
      width: width,
    },
    {
      alt: "Vite",
      title: "Vite",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/vite.png",
      width: width,
    },
    {
      alt: "Docker",
      title: "Docker",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/docker.png",
      width: width,
    },
    {
      alt: "Kubernetes",
      title: "Kubernetes",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/kubernetes.png",
      width: width,
    },
    {
      alt: "AWS",
      title: "AWS",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/aws.png",
      width: width,
    },
    {
      alt: "redis",
      title: "redis",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/redis.png",
      width: width,
    },
    {
      alt: "Hibernate",
      title: "Hibernate",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/hibernate.png",
      width: width,
    },
    {
      alt: "Oracle",
      title: "Oracle",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/oracle.png",
      width: width,
    },
    {
      alt: "PostgreSQL",
      title: "PostgreSQL",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/postgresql.png",
      width: width,
    },
    {
      alt: "MySQL",
      title: "MySQL",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/mysql.png",
      width: width,
    },
    {
      alt: "MSSQL",
      title: "MSSQL",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/mssql.png",
      width: width,
    },
    {
      alt: "Maven",
      title: "Maven",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/maven.png",
      width: width,
    },
    {
      alt: "bash",
      title: "bash",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/bash.png",
      width: width,
    },
    {
      alt: "Gulp",
      title: "Gulp",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/gulp.png",
      width: width,
    },
    {
      alt: "REST",
      title: "REST",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/rest.png",
      width: width,
    },
    {
      alt: "Lombok",
      title: "Lombok",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/lombok.png",
      width: width,
    },
    {
      alt: "webpack",
      title: "webpack",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/webpack.png",
      width: width,
    },
    {
      alt: "NumPy",
      title: "NumPy",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/numpy.png",
      width: width,
    },
    {
      alt: "gRPC",
      title: "gRPC",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/grpc.png",
      width: width,
    },
    {
      alt: "Jupyter Notebook",
      title: "Jupyter Notebook",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/jupyter_notebook.png",
      width: width,
    },
    {
      alt: "Tomcat",
      title: "Tomcat",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/tomcat.png",
      width: width,
    },
    {
      alt: "Pandas",
      title: "Pandas",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/pandas.png",
      width: width,
    },
    {
      alt: "Nginx",
      title: "Nginx",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/nginx.png",
      width: width,
    },
    {
      alt: "TensorFlow",
      title: "TensorFlow",
      src: "https://raw.githubusercontent.com/marwin1991/profile-technology-icons/refs/heads/main/icons/tensorflow.png",
      width: width,
    },
  ];
};

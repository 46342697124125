import { React, useEffect, useRef } from "react";
import { Segment } from "semantic-ui-react";
import "./HomePage.css";
import { useParams } from "react-router-dom";
import {
  HomeComponent,
  AboutComponent,
  ExperienceComponent,
  SkillsComponent,
  ContactComponent,
} from "../../components";
import ProjectsComponent from "../../components/projects/ProjectsComponent";

export default function HomePage() {
  const about = useRef(null);
  const skills = useRef(null);
  const projects = useRef(null);
  const experience = useRef(null);
  const contact = useRef(null);

  const handleHomeClick = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  const handleAboutClick = () => {
    about.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleExperienceClick = () => {
    experience.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleSkillsClick = () => {
    skills.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleContactClick = () => {
    contact.current?.scrollIntoView({ behavior: "smooth" });
  };
  const handleProjectsClick = () => {
    projects.current?.scrollIntoView({ behavior: "smooth" });
  };

  let header = useParams()["header"];
  useEffect(() => {
    switch (header) {
      case "about":
        handleAboutClick();
        break;
      case "experience":
        handleExperienceClick();
        break;
      case "skills":
        handleSkillsClick();
        break;
      case "contact":
        handleContactClick();
        break;
      case "projects":
        handleProjectsClick();
        break;
      default:
        handleHomeClick();
        break;
    }
  }, [header]);

  return (
    <div className="homepage">
      {/* Header */}
      <Segment className="seg-header" inverted vertical textAlign="center">
        <HomeComponent></HomeComponent>
      </Segment>

      {/* About */}
      <Segment style={{ padding: "8em 0em" }} vertical>
        <AboutComponent ref={about}></AboutComponent>
      </Segment>

      {/* Experience */}
      <Segment style={{ padding: "8em 0em" }} vertical>
        <ExperienceComponent ref={experience}></ExperienceComponent>
      </Segment>

      {/* Skills */}
      <Segment style={{ padding: "8em 0em" }} vertical>
        <SkillsComponent ref={skills}></SkillsComponent>
      </Segment>

      {/* Projects */}
      <Segment style={{ padding: "4em 0em" }} vertical>
        <ProjectsComponent ref={projects}></ProjectsComponent>
      </Segment>

      {/* Contact */}
      <Segment style={{ padding: "8em 0em" }} vertical>
        <ContactComponent ref={contact}></ContactComponent>
      </Segment>
    </div>
  );
}
